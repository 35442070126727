html, body {
  background-color: #282c34;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.App {
  text-align: center;
}


h1 {
  margin: 0.5em auto 1em auto!important;
  font-weight: 300!important;
}